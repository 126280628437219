.dynamicform-container {
  padding: 3%;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  margin-bottom: 2%;
}
.dynamicform-with-border {
  border: 2px solid #e1e1e1; /* Customize the border color */
  border-radius: 8px; /* Optional: Rounded corners */
  padding: 15px; /* Add padding for better spacing */
  margin-bottom: 20px; /* Space between dynamic forms */
  background-color: #f9f9f9; /* Optional: Background color */
}

.dynamicform {
  
}
.atob{
  color: white;
    background: red;
    width: 120px;
    text-align: center;
    padding: 1%;
    border-radius: 7px;
}

.btoa{
  color: white;
    background: #556ee6;
    width: 120px;
    text-align: center;
    padding: 1%;
    border-radius: 7px;
}

.dynamicform .form-section {
  margin-bottom: 10px; /* Space between A to B and B to A forms */
}

.dynamicform .form-section:last-child {
  margin-bottom: 0;
}

  .addbtn{
    background-color: #34c38f !important;
    color: #fff !important;
  }